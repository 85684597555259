import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="causeselect"
export default class extends Controller {
  connect() {
  }

  initialize() {
    this.element.setAttribute("data-action", "change->causeselect#loaddetails")
  }

  loaddetails() {
    const selectedCause = this.element.value
    this.url = `/blockages/fetch_details?cause=${selectedCause}`

  }
}
